<template>
  <div class="w-full flex flex-col">
     <loader :loading="loading" :backdrop="true"/>
    <bread-crumbs class="flex justify-end text-xs" :items="routes" />
    <h3 class="text-start mt-4 text-xl">Manifiestos</h3>
    <h4 class="text-start mt-1 mb-4 text-gray-500 text-lg">Listado</h4>
    <section class="w-full bg-white p-2">
      <div class="w-full flex justify-between">
        <label>Filtro</label>
        <button @click.stop="handleToggle">
          <minus-icon v-if="isToggle" class="w-4 h-4" />
          <plus-icon v-else class="w-4 h-4" />
        </button>
      </div>
      <div class="w-full flex flex-col">
        <b-form @submit="applyFilters">
          <div>
            <div class="flex grid grid-cols-5 gap-4 mt-4">
              <input-field
                idInput="keyWord"
                idFormGroup="keyWord-group"
                type="text"
                label="Palabra Clave"
                classLabel="mt-1"
                classInput="input-filter"
                v-model="filterForm.keyWord"
              />
              <select-input
                class="mx-2 w-full"
                idInput="filterByInput"
                idFormGroup="filterBy-group"
                label="Filtrar por"
                v-model="filterForm.filterBySelect"
                :options="filterListOptions"
              />
              <select-input
                class="w-full"
                idInput="operator"
                idFormGroup="operator-group"
                label="Operador"
                v-model="filterForm.operatorSelect"
                :options="operatorListOptions"
              />
              <input-field
                class="mx-2"
                idInput="from-date"
                idFormGroup="fromDate-group"
                type="date"
                label="Desde"
                classLabel="mt-1"
                classInput="input-filter"
                v-model="filterForm.keyWord"
              />
              <input-field
              idInput="to-date"
              idFormGroup="toDate-group"
              type="date"
              label="Hasta"
              classLabel="mt-1"
              classInput="input-filter"
              v-model="filterForm.keyWord"
            />
            </div>
            <div class="flex grid grid-cols-3 gap-4 mt-4" v-if="isToggle">
            <select-input
              idInput="sortBySelect"
              idFormGroup="sort-group"
              label="Ordenar por"
              v-model="filterForm.sortBy"
              :options="sortByListOptions"
            />
            <select-input
              idInput="sortWaySelect"
              idFormGroup="sortWay-group"
              label="Ordenar de forma"
              v-model="filterForm.agent"
              :options="sortWayListOptions"
            />
            <select-input
              idInput="statusSelect"
              idFormGroup="status-group"
              label="Estado"
              v-model="filterForm.state"
              :options="statusListOptions"
            />
            </div>
          </div>
        </b-form>
      </div>
    </section>
    <table-manifest @loader="handleLoader" class="mt-4 "/>
  </div>
</template>

<script>
import InputField from '@/components/InputField.vue';
import SelectInput from '@/components/SelectInput.vue';
import PlusIcon from '@/components/icons/PlusIcon.vue';
import MinusIcon from '@/components/icons/MinusIcon.vue';
import BreadCrumbs from '@/components/BreadCrumbs.vue';
import TableManifest from '@/components/TableManifest.vue';
import Loader from '@/components/Loader.vue';

export default {
  name: 'Manifest',
  components: {
    PlusIcon,
    MinusIcon,
    InputField,
    SelectInput,
    BreadCrumbs,
    TableManifest,
    Loader,
  },
  data() {
    return {
      loading: false,
      isToggle: false,
      filterForm: {
        keyWord: '',
        filterBySelect: null,
        operatorSelect: null,
        client: null,
        agent: null,
        state: '',
        payment: '',
        sortBy: null,
        office: null,
      },
      routes: [
        { name: 'Inicio', path: '/' },
      ],
      filterListOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'Número', value: 'number' },
        { text: 'Código', value: 'code' },
        { text: 'Número & Código', value: 'all' },
      ],
      operatorListOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'Contiene', value: 'contiene' },
        { text: 'Exacto', value: 'contiene' },
        { text: 'Mayor que', value: 'contiene' },
        { text: 'Menor que', value: 'contiene' },
      ],
      sortByListOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'ID', value: 'id' },
      ],
      sortWayListOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'Decendiente', value: 'decendiente' },
        { text: 'Ascendiente', value: 'ascendiente' },
      ],
      statusListOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'Activo', value: 1 },
        { text: 'Inactivo', value: 0 },
      ],
      packages: [
        {
          gvt: 'GVY108430',
          agent: '',
          client: 'Manuel Garro Campos',
          state: 'Procesando',
          payment: 'Pendiente',
          invoice: '$13.4',
        },
        {
          gvt: 'GVY108430',
          agent: '',
          client: 'Manuel Garro Campos',
          state: 'Procesando',
          payment: 'Pendiente',
          invoice: '$13.4',
        },
        {
          gvt: 'GVY108430',
          agent: '',
          client: 'Manuel Garro Campos',
          state: 'Procesando',
          payment: 'Pendiente',
          invoice: '$13.4',
        },
        {
          gvt: 'GVY108430',
          agent: '',
          client: 'Manuel Garro Campos',
          state: 'Procesando',
          payment: 'Pendiente',
          invoice: '$13.4',
        },
      ],
    };
  },
  methods: {
    handleToggle() {
      this.isToggle = !this.isToggle;
    },
    applyFilters() {
      // Logic here
    },
    loadRoutesBreadCrumbs(router) {
      const {
        history: { _startLocation, current, router: { options: { routes } } },
      } = router;
      const startRoute = routes.find((route) => route.path === _startLocation);
      this.routes.push(startRoute);
      if (startRoute.name !== current.name) this.routes.push(current);
    },
    handleLoader(loader) {
      this.loading = loader;
    },
  },
  mounted() {
    this.loading = true;
    this.loadRoutesBreadCrumbs(this.$router);
  },
};
</script>
