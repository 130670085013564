<template>
  <div class="w-full bg-white p-2 overflow-auto">
    <div class="w-full flex justify-between mb-4">
        <label class="self-center my-2">Últimos 30 dias</label>
      </div>
    <b-pagination
      class="mt-4"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
    ></b-pagination>
    <b-table
      class="my-2 text-sm text-center"
      id="dashboardTable"
      striped
      hover
      :fields="fields"
      :items="items"
      responsive="sm"
      :per-page="perPage"
      :current-page="currentPage"
    >
     <template #cell(number)="data">
         <b class="text-information cursor-pointer">
          <router-link
            class="no-underline"
            :to="{ name: 'AWBs Detail', params: { id: data.item.id, number: data.value   } }">
            {{data.value}}
          </router-link>
        </b>
      </template>
      <template #cell(created_at)="data">
        {{ formatDateTime(data.value) }}
      </template>

      <template #cell(enabled)="data">
        <div class="flex justify-center">
            <div
            class="px-2 py-2 text-white rounded"
            :class="data.value ? 'bg-green-500' : 'bg-red-500'">
            <span> {{data.value ? 'Activo' : 'Inactivo'}}</span>
          </div>
        </div>
      </template>

      <template #cell(warehouse_id)="data">
         <div class="flex justify-center">
              <div
            class="px-2 py-2 text-white self-center rounded "
            :class="data.item.enabled ? 'bg-blue-400' : 'bg-gray-300'">
            <icon class="text-center m-auto" name="ListIcon" />
          </div>
        </div>
      </template>

      <template #cell(delete)>
         <div class="flex justify-center">
               <div
            class="px-2 py-2 text-white self-center rounded text-xs bg-red-500" >
            <icon class="text-center m-auto" name="TrashIcon" />
          </div>
        </div>
      </template>

      <template #cell(print)>
        <div class="flex justify-center">
          <div
            class="px-2 py-2 mr-4 text-white self-center rounded text-xs bg-gray-500" >
            <icon class="text-center m-auto" name="PrinterIcon" />
          </div>
          <div
            class="px-2 py-2 text-white self-center rounded text-xs bg-gray-500" >
            <icon class="text-center m-auto" name="DocumentTextIcon" />
          </div>
        </div>
      </template>

      <template #cell(download)>
        <div class="flex justify-center">
          <div
            class="px-2 py-2 mr-4 text-white self-center rounded  bg-green-700" >
            .pdf
          </div>
          <div
            class="px-2 py-2 text-white self-center rounded  bg-green-700" >
            .xlsx
          </div>
        </div>
      </template>

      <template #cell(closed)="data">
         <div class="flex justify-center">
              <div
            class="px-2 py-2 text-white self-center rounded "
            :class="data.value ? 'bg-red-500' : 'bg-gray-400'">
            <icon v-if="data.value" class="text-center m-auto" name="LockOpenIcon" />
            <icon v-else class="text-center m-auto" name="LockClosedIcon" />
          </div>
        </div>
      </template>

    </b-table>
    <b-pagination
      class="mt-4"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
    ></b-pagination>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/Icon.vue';
import request from '@/api/request-services';
import TimezoneMixin from '@/mixins/TimezoneMixin';

export default {
  name: 'DashboardTable',
  mixins: [TimezoneMixin],
  components: { Icon },
  computed: {
    rows() {
      return this.items.length;
    },
    ...mapGetters({
      getToken: 'authentication/getAuthToken',
    }),
  },
  methods: {
    handleCreateAdmin() {
      const role = 5;
      this.$router.push({ name: 'Crear Usuario', params: { role } });
    },
    async handleData() {
      const response = await request.requestGET('awbs', this.getToken);
      if (response.status === 200) {
        this.$emit('loader', false);
        this.items = response.data;
      }
    },
  },
  mounted() {
    this.handleData();
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      items: [],
      fields: [
        { key: 'id', label: '#' },
        { key: 'number', label: 'Número' },
        { key: 'created_at', label: 'Fecha' },
        { key: 'enabled', label: 'Estado' },
        { key: 'warehouse_id', label: 'Abrir' },
        { key: 'delete', label: 'Borrar' },
        { key: 'print', label: 'Imprimir' },
        { key: 'download', label: 'Descargar' },
        { key: 'closed', label: 'Cerrar' },
      ],
    };
  },
};
</script>

<style scoped>
  .text-information {
    color: var(--primary-blue) !important;
  }
</style>
